import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import TituloCategorias from '../components/TituloCategorias';
import TarjetaPoema from '../components/Cards/TarjetaPoema';
/**
 * Plantilla para listar los poemas de cada
 * categoría.
 */
const PlantillaCategoria = (props) => {
  // Consulta de datos
  const { allMdx: { nodes: posts } } = props.data;

  // Obtenemos la categoría actual y la información de su detalle
  // const { pageContext: { category } } = props;
  const detalleCategoria = props.data.categoriasJson;

  // Renderizado
  return (
    <Layout>
      <TituloCategorias>{detalleCategoria.nombre_completo}</TituloCategorias>
      <ListaCategorias>
        {posts.map(post => {
          return (
            <TarjetaPoema
              enlace={`/poemas/${post.frontmatter.slug}`}
              titulo={post.frontmatter.title}
              fecha={post.frontmatter.fecha} />
          );
        })}
      </ListaCategorias>
    </Layout>
  );
};

// Consulta para obtener la lista de categorías
export const queryCategorias = graphql`
query GetPoemsByCategory($category: String) {
  imageSharp(fluid: {originalName: {eq: "watercolor-categories.png"}}) {
    fluid(maxWidth: 400, maxHeight: 250) {
        ...GatsbyImageSharpFluid
    }
  },
  allMdx(
      filter: {frontmatter: {category: {eq: $category}}}
    ) {
      nodes {
        frontmatter {
          date
          title
          category
          slug
          fecha
        }
        id
      }
      totalCount
    },
  categoriasJson(categoria: {eq: $category}) {
    categoria
    nombre_completo
  }
}`;

// Componente para dar estilo a la lista de poemas
const ListaCategorias = styled.div`
`;

export default PlantillaCategoria;